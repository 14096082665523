:root {
    /* Colors */

    /* Use this color as the background
    for your dark-on-light designs,
    or the text color of an inverted design. */
    --color-light-shades: #f7f7f6;

    /* Accent colors can be used to
    bring attention to design elements
    by contrasting with the rest of the palette. */
    --color-light-accent: #8a9b7a;

    /* This color should be eye-catching but not harsh.
    It can be liberally applied to your layout
    as its main identity. */
    --color-main: #6a6b3f;

    /* Another accent color to consider.
    Not all colors have to be used -
    sometimes a simple color scheme works best. */
    --color-dark-accent: #999268;

    /* Use as the text color for dark-on-light designs,
    or as the background for inverted designs. */
    --color-dark-shades: #1c212a;
}
