@import "./vars.css";

/* Default CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: var(--color-dark-shades) !important;
    color: var(--color-light-shades) !important;
    font-family: "OpenDyslexic", "Chakra Petch", monospace !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p,
a,
li,
button,
ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a :hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

@media (min-width: 1700xp) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

/* Navbar Css */

nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
    background-color: var(--color-dark-shades);

    box-shadow: 0 5px 5px 0 #0f1115;
}

nav.navbar.scrolled {
    box-shadow: 0 5px 5px 0 #0f1115;
    padding: 0px 0;
    background-color: var(--color-main);
}

nav.navbar .navbar-brand img {
    max-height: 100px;
    max-width: 100px;
    margin: 10px;
    border-radius: 25%;
    border: 2px solid var(--color-light-shades);
}

nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: var(--color-light-shades) !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link:active {
    opacity: 1;
}

span.navbar-text {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.lang-menu {
    padding: 5px;
    position: relative;
    color: var(--color-light-shades) !important;
}

.selected-lang-de {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 2;
    width: 70px;
}

.selected-lang-de:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(https://www.flagsapi.com/DE/flat/32.png);
}

.selected-lang-en {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 2;
    width: 70px;
}

.selected-lang-en:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(https://www.flagsapi.com/GB/flat/32.png);
}

.lang-menu ul {
    margin: 0;
    padding: 0;
    background-color: var(--color-dark-shades);
    border: 1px solid var(--color-light-shades);
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 1;
}

.lang-menu ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.lang-menu ul li a {
    cursor: pointer;
    width: 100px;
    display: block;
    padding: 5px 10px;
    color: var(--color-light-shades) !important;
    text-decoration: none;
}

.lang-menu ul li a:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 10px;
}

.lang-menu ul li a:hover {
    background-color: rgba(217, 217, 217, 0.5);
}

.de::before {
    background-image: url(https://www.flagsapi.com/DE/flat/32.png);
}

.en::before {
    background-image: url(https://www.flagsapi.com/GB/flat/32.png);
}

.lang-menu:hover ul {
    display: block;
}

.social-icon {
    display: inline-block;
}

.social-icon a {
    width: 50px;
    height: 50px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 2px solid rgba(217, 217, 217, 0.5);
}

.social-icon a::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
    transform: scale(1);
}

.social-icon a img {
    z-index: 1;
    transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
    filter: brightness(100%) saturate(100%) invert(100%) sepia(7%) saturate(98%)
        hue-rotate(346deg) brightness(95%) contrast(86%);
}

.vvd {
    border: 1px solid var(--color-light-shades);
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.vvd a {
    text-decoration: none;
    font-weight: 700;
    color: var(--color-light-shades) !important;
    font-size: 18px;
    position: relative;
    transition: 0.3s ease-in-out;
}

.vvd::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: var(--color-light-shades);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.vvd:hover a {
    color: #121212 !important;
}

.vvd:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid var(--color-light-shades);
    transition: all 300ms linear;
    top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid var(--color-light-shades);
}

nav.navbar .navbar-toggler-icon::after,
nav.navbar .navbar-toggler-icon::before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: var(--color-light-shades);
    top: 0;
    left: 0;
    content: "";
    z-index: 2;
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon::after {
    top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
    transform: rotate(45deg);
    background-color: var(--color-light-shades);
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
    transform: translateY(8px) rotate(-45deg);
    background-color: var(--color-light-shades);
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

/* Banner CSS */

.banner {
    margin-top: 0;
    margin-bottom: 80px;
    padding: 260px 0 100px 0;
    background: var(--color-dark-shades);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    color: var(--color-light-shades);
    border: 3px solid var(--color-main);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
    border-radius: 10px;
}
.banner h1 {
    font-size: 70px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    font-family: "Chakra Petch";
    background: linear-gradient(
        90deg,
        var(--color-dark-accent),
        var(--color-light-accent)
    );
    background-clip: border-box;

    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.banner p {
    padding: 10px;
    color: var(--color-light-shades);
    opacity: 0.8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
    border-radius: 3%;
}
.banner a {
    text-decoration: none;
    color: var(--color-light-shades);
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}
.banner a svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}
.banner a:hover svg {
    margin-left: 25px;
}
.banner img {
    box-shadow: 0 16px 14px 0 #0f1115;
    border-radius: 100%;
    animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
    border-right: 0.08em solid #666;
}

/* Skills CSS */

.skill {
    margin-bottom: 80px;
    position: relative;
}

.skill-bx {
    background: #0f1115;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
    border: 5px solid var(--color-main);
    box-shadow: 0 5px 5px 0 #0f1115;
}

.skill h2 {
    font-size: 45px;
    font-weight: 700;
}

.skill p {
    color: var(--color-light-shades);
    opacity: 0.8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 50px 0 25px 0;
}

.skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}
.skill-slider button {
    z-index: 5;
}

.skill-slider .item img {
    width: 50%;
    margin: 0 auto 30px auto;
}

.skill-slider .item h5 {
    margin: 0 auto 30px auto;
}

.skill-slider .custom-dot-list-style .react-multi-carousel-dot--active button {
    background: var(--color-light-accent);
}

/* Projects CSS */

.project {
    margin-bottom: 80px;
    position: relative;
    background-color: var(--color-dark-shades);
}
.project h2 {
    color: var(--color-light-shades);
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}
.project p {
    color: var(--color-light-shades);
    opacity: 0.8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}
.project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;

    box-shadow: 0 5px 5px 0 #0f1115;
}
.project .nav.nav-pills .nav-item {
    width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: var(--color-light-shades);
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: var(--color-main);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

.proj-container {
    display: flex;
    flex-direction: column;
    border: 5px solid var(--color-main);
    border-radius: 10px;
    background: #0f1115;
    box-shadow: 0 16px 14px 0 #0f1115;
    padding: 18px 24px;
    min-width: 430px;
}

.proj-container img {
    margin-bottom: 25px;
    border-radius: 10px;
}

/* .proj-imgbx::before {
    content: "";
    background: linear-gradient(
        90.21deg,
        var(--color-dark-accent) -5.91%,
        var(--color-light-accent) 111.58%
    );
    opacity: 0.95;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
    height: 100%;
}
.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
    color: var(--color-dark-shades);
}
.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
} */
.proj-container h3 {
    color: var(--color-light-shades);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}
.proj-container p {
    color: var(--color-light-shades);
    margin: 10px;
    font-weight: 500;
    font-size: 25px;

    opacity: 0.9;
    font-size: 25px;
    text-align: left;
    width: 100%;
}

.proj-container ul {
    margin-left: 0;
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    list-style: none;
    padding-inline-start: 6px;
}

.proj-container li {
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.8px;
    border-radius: 100px;
    color: var(--color-dark-shades);
    background: var(--color-light-accent);
    padding: 2px 22px;
}

.proj-container a {
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    justify-content: space-around;
    font-family: "Chakra Petch";
    color: var(--color-dark-shades);
    font-size: 30px;
    font-weight: 600;
    border-radius: 100px;
    background: var(--color-dark-accent);
    padding: 1px 22px;
}

.mason-img {
    width: "100%";
    display: "block";

    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: 0.5s all;
}

.mason-img:hover {
    opacity: 0.8;
    transition: 0.5s all;
}

.img-viewer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 100000;
    backdrop-filter: blur(10px);
}
.img-viewer img {
    width: auto;
    max-width: 80%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.img-viewer button {
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    width: 3rem;
    height: 3rem;
}
.img-viewer svg {
    width: 100%;
    height: 100%;
}

/* Footer */

.footer {
    padding: 20px;
    background-color: var(--color-main);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer img {
    display: inline-block;
    margin-left: 14px;
    width: 50px;
    border: 2px solid rgba(217, 217, 217, 0.5);
    border-radius: 50%;
}

.footer p {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-light-shades);
    opacity: 0.7;
    letter-spacing: 0.5px;
    margin-top: 20px;
}

.footer a {
    color: var(--color-dark-shades);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
